var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseLayout',{staticClass:"px-0",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-icon',{staticClass:"pb-1"},[_vm._v("mdi-list-box-outline")]),_vm._v(" NUS-koderegister ")]},proxy:true}])},[(_vm.modalData.showModal)?_c('BaseModal',{attrs:{"maxWidth":600}},[_c('NusCodeModal',{attrs:{"headline":_vm.modalData.modalHeadline,"modalType":_vm.modalData.modalType,"existingItemModal":_vm.modalData.existingItem},on:{"close":function($event){return _vm.closeModal(_vm.modalData)},"refreshNusCodes":function($event){return _vm.refreshNusCodes()}}})],1):_vm._e(),[_c('BaseTableFiltered',{attrs:{"headers":_vm.headers,"items":_vm.nusCodes,"search":_vm.search,"sort-by":"code","titleClass":"py-0"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-spacer'),_c('v-text-field',{staticClass:"pl-16 ml-16",attrs:{"append-icon":"mdi-magnify","label":"Søk","single-line":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('BaseTooltipIconButton',{attrs:{"color":"primary","btnText":"Lag ny NUS-kode","icon":"mdi-plus","data-cy":"openCreateNusCode"},on:{"handleClick":function($event){return _vm.openCreateNusCode()}}})]},proxy:true},{key:"item.isActive",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? "Aktiv" : "Inaktiv")+" ")]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"data-cy":"openEditNusCode"},on:{"click":function($event){return _vm.openEditNusCode(item, item.name)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Rediger "+_vm._s(item.name))])])]}}],null,true)})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }