



















































import { api } from "@/api/api";
import { ApiGetNusCodeDto } from "@/api/generated/Api";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, PropType, ref } from "@vue/composition-api";
import { validateNotEmpty, validateMaxLength, validateNusCode } from "@/shared/helpers/validationHelpers";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";

export default defineComponent({
  name: "NusCodeModal",
  components: { BaseModalForm },
  props: {
    modalType: {
      type: String as PropType<ModalType>,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    existingItemModal: {
      type: Object as PropType<ApiGetNusCodeDto>,
    },
  },
  setup(props, { emit, refs }) {
    const store = useStore<StoreState>();
    const nusCode = ref<ApiGetNusCodeDto>(getInitialNusCodeData());
    const isEditModal = props.modalType === ModalType.Edit;

    if (isEditModal) {
      nusCode.value = deepCloneObject(props.existingItemModal as ApiGetNusCodeDto);
    }

    const handleSubmit = () => {
      const isValid = getValidatableRef(refs.connectForm)?.validate();
      if (!isValid) {
        openNotification(store, NotificationItemType.Error, "Mangler informasjon, alle felter er påkrevd");
        return;
      }
      if (props.modalType === ModalType.Add) {
        createNusCode();
      } else if (isEditModal) {
        updateNusCode();
      } else {
        openNotification(store, NotificationItemType.Error, "Det skjedde en feil");
      }
    };

    const createNusCode = () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await api.curriculum.createNusCodeAsync(nusCode.value);
        openNotification(store, NotificationItemType.Success, "NUS-kode er opprettet");
        emit("refreshNusCodes");
        emit("close");
      });
    };

    const updateNusCode = () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        // Assume that code is not null/undefined as it should be required when creating nus code
        await api.curriculum.updateNusCodeAsync(nusCode.value.code as string, {
          name: nusCode.value.name,
          shortName: nusCode.value.shortName,
          educationalOfferCode: nusCode.value.educationalOfferCode,
          isActive: nusCode.value.isActive,
        });
        openNotification(store, NotificationItemType.Success, "NUS-kode er lagret");
        emit("refreshNusCodes");
        emit("close");
      });
    };

    return {
      handleSubmit,
      validateNotEmpty,
      validateMaxLength,
      validateNusCode,
      nusCode,
      isEditModal,
    };
  },
});

const getInitialNusCodeData = (): ApiGetNusCodeDto => ({
  name: "",
  isActive: true,
  ownerOrganizationId: -1,
  code: "",
  educationalOfferCode: "",
  shortName: "",
});
